import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableHeader } from '@material-table/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOrdersPerPage } from 'api/corememData';
import { Event } from 'SocketIO';
import { TableCell } from '@material-ui/core';
import { dateToDateTimeString } from 'assets/js/common';
import { makeStyles } from '@material-ui/core/styles';
import { getValueColor } from 'assets/js/common';

import _ from 'lodash';

const useStyle = makeStyles(theme => {
  return {
    buyColor: {
      color: theme.palette.orders.buy,
    },
    sellColor: {
      color: theme.palette.orders.sell,
    },
  };
});

export default function MaterialTableDemo(props) {
  const { exchange, pair, heads = [], bigger } = props;
  const tableRef = useRef();
  const classes = useStyle();
  const [rowCount, setRowCount] = useState(
    _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
      ? 5
      : parseInt(localStorage.getItem('recentTradesOverViewSidebar')),
  );

  const loadData = query => {
    const savedRowCount = _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
      ? 5
      : parseInt(localStorage.getItem('recentTradesOverViewSidebar'));
    const newRowCount = query.pageSize > savedRowCount ? query.pageSize : savedRowCount;
    query.pageSize = newRowCount;

    if (!exchange) {
      return Promise.resolve({
        page: 0,
        data: [],
        totalCount: 0,
      });
    }
    return getOrdersPerPage(`${exchange}/${pair}`, query);
  };

  const refreshData = () => {
    if (!_.isNil(tableRef)) {
      if (!_.isNil(tableRef.current)) {
        tableRef.current && tableRef.current.onQueryChange();
      }
    }
  };

  const updateRowCount = tableRef => {
    if (!_.isNil(tableRef)) {
      if (!_.isNil(tableRef.current)) {
        const savedRowCount = _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
          ? 5
          : parseInt(localStorage.getItem('recentTradesOverViewSidebar'));
        setRowCount(savedRowCount);
        if (tableRef.current.dataManager.pageSize !== savedRowCount) {
          localStorage.setItem('recentTradesOverViewSidebar', tableRef.current.dataManager.pageSize.toString());
        }
      }
    }
  };

  /*
  const initializeRowCount = (tableRef) => {
    const savedRowCount = _.isNil(localStorage.getItem('recentTradesOverViewSidebar')) ? 5 : parseInt(localStorage.getItem('recentTradesOverViewSidebar'))
    setRowCount(savedRowCount)
  }
  */

  useEffect(() => {
    refreshData();
    updateRowCount(tableRef);
  }, [exchange, pair]);

  useEffect(() => {
    var timer1 = setInterval(function () {
      timer1 = null;
      updateRowCount(tableRef);
    }, 30000);

    var timer2 = setInterval(function () {
      timer2 = null;
      refreshData();
    }, 30000);

    return () => {
      timer1 && clearInterval(timer1);
      timer2 && clearInterval(timer2);
    };
  }, []);

  let columnDefinitions = [
    { title: 'Rate', field: 'rate' },
    { title: 'Amount', field: 'amount' },
    { title: 'Time', field: 'time', type: 'datetime' },
  ];
  if (heads.length > 0) {
    columnDefinitions = [];
    heads.forEach(element => {
      if (element.title === 'Rate') {
        columnDefinitions.push({ title: 'Rate', field: 'rate' });
      } else if (element.title === 'Pnl') {
        columnDefinitions.push({ title: 'Pnl', field: 'pnl' });
      } else if (element.title === 'Amount') {
        columnDefinitions.push({ title: 'Amount', field: 'amount' });
      } else if (element.title === 'Amount (base)') {
        columnDefinitions.push({ title: 'Amount (base)', field: 'amountBase' });
      } else if (element.title === 'Time') {
        columnDefinitions.push({ title: 'Time', field: 'time', type: 'datetime' });
      }
    });
  }

  return (
    <Event event="connect" handler={refreshData}>
      <MaterialTable
        style={bigger ? { marginLeft: '-8px', minWidth: '100%', maxWidth: '100%', minHeight: '100%' } : { marginLeft: '-8px', minWidth: '100%', maxWidth: '100%' }}
        title="Recent trades"
        columns={columnDefinitions}
        options={{
          toolbar: bigger,
          pageSize: rowCount,
          headerStyle: {
            padding: '6px 10px 6px 10px',
          },
          rowStyle: {
            padding: '6px 10px 6px 10px',
          },
        }}
        data={loadData}
        editable={null}
        tableRef={tableRef}
        components={{
          OverlayLoading: () => {
            return (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color={'secondary'} />
              </div>
            );
          },
          Header: function (props) {
            return <MTableHeader {...props} size={bigger ? 'medium' : 'small'} />;
          },
          Cell: function ({ columnDef, value, rowData }) {
            let cell;
            switch (columnDef.field) {
              case 'rate':
                cell = <span className={rowData.type === 'buy' ? classes.buyColor : classes.sellColor}>{value}</span>;
                break;
              case 'pnl':
                cell = <span style={{ color: getValueColor(value) }}>{value}</span>;
                break;
              case 'amountBase':
                cell = <span>{(rowData.rate * rowData.amount).toFixed(4)}</span>;
                break;
              case 'time':
                cell = <span>{dateToDateTimeString(value)}</span>;
                break;
              default:
                cell = <span>{localStorage.getItem('privacyMode') == 'true' ? '●●●●●' : value}</span>;
                break;
            }
            return (
              <TableCell size={bigger ? 'medium' : 'small'} style={{ whiteSpace: 'nowrap' }}>
                {cell}
              </TableCell>
            );
          },
        }}
      />
    </Event>
  );
}

MaterialTableDemo.propTypes = {
  exchange: PropTypes.string,
  pair: PropTypes.string,
};
